import React, { useState } from "react";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import liebestraum from '../music/Franz Liszt/Liebestraum_ No.3 in A-Flat Major, S541.mp3';
import consolations from '../music/Franz Liszt/Consolations_ No. 3 in D-Flat Major,  S172.mp3'
import ballade from '../music/Frédéric Chopin/Ballade No. 1 In G-Minor, Op 23.mp3'
import sospiro from  '../music/Franz Liszt/3 Études de Concert_ Un Sospiro in D-Flat Major, S144.mp3'

const songs = {ballade, sospiro, liebestraum, consolations}
const songNum = 0

const songClicked = (event, songIndex) => {
    songNum = songIndex
}

class Music extends React.Component {
  render() {
    return (
      <section id="music" className="about-mf sect-pt4 route">
        <div className="container">
        <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Music</h3>
                <p className="subtitle-a">
                  Some of my favourite recordings.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
            
            <List>
      <ListItem button onClick={(event) => songClicked(event, 0)}>
        <ListItemText primary="Ballade No. 1 in G-Minor, Op 23" secondary="Frédéric Chopin"/>
      </ListItem>
      <ListItem button onClick={(event) => songClicked(event, 1)}>
        <ListItemText primary="3 Études de Concert, 'Un Sospiro' in D-Flat Major, S144" secondary="Franz Liszt" />
      </ListItem>
      <ListItem button onClick={(event) => songClicked(event, 2)}>
        <ListItemText primary="Liebestraum No.3 in A-Flat Major, S541" secondary="Franz Liszt" />
      </ListItem>
      <ListItem button onClick={(event) => songClicked(event, 3)}>
        <ListItemText primary="Consolations No. 3 in D-Flat Major,  S172" secondary="Franz Liszt" />
      </ListItem>
    </List>

            <div className="container">
              <div className="col-sm-0">
                <AudioPlayer
                  layout="stacked-reverse"
                  autoPlay={false}
                  autoPlayAfterSrcChange={true}
                  showSkipControls={true}
                  showJumpControls={false}
                  onPlay={e => console.log("onPlay")}
                  src={songs[songNum]}
                  //onEnded={handlePlayNext}
                  //onClickPrevious={handleClickPrevious}
                  //onClickNext={handleClickNext}
                  //customIcons={{play: playIcon, pause: pauseIcon,loop: repeatIcon,loopOff: repeatIcon}}
                />
              </div>
            </div>
            
          </div>
        </div>
      </section>
    );
  }
}

export default Music;
